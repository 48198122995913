html.dark {
  background-color: rgb(15, 23, 42);
}

.slanted-hint-bg-300 {
  border-color: #ffffff;
  background-color: #22c55e;
  background-image: -webkit-linear-gradient(140deg, #fde047 50%, #86efac 50%);
}

.slanted-hint-bg-500 {
  border-color: #ffffff;
  background-color: #22c55e;
  background-image: -webkit-linear-gradient(140deg, #eab308 50%, #22c55e 50%);
}

.slanted-hint-bg-500-dark {
  border-color: rgb(15, 23, 42);
  background-color: #22c55e;
  background-image: -webkit-linear-gradient(140deg, #eab308 50%, #22c55e 50%);
}

.slanted-hint-bg-900 {
  border-color: rgb(15, 23, 42);
  background-color: #22c55e;
  background-image: -webkit-linear-gradient(140deg, #713f12 50%, #14532d 50%);
}

.slanted-hint-bg-hc-300 {
  border-color: #ffffff;
  background-color: #67e8f9;
  background-image: -webkit-linear-gradient(140deg, #fdba74 50%, #67e8f9 50%);
}

.slanted-hint-bg-hc-500 {
  border-color: #ffffff;
  background-color: #06b6d4;
  background-image: -webkit-linear-gradient(140deg, #06b6d4 50%, #f97316 50%);
}

.slanted-hint-bg-hc-500-dark {
  border-color: rgb(15, 23, 42);
  background-color: #06b6d4;
  background-image: -webkit-linear-gradient(140deg, #06b6d4 50%, #f97316 50%);
}

.slanted-hint-bg-hc-900 {
  border-color: rgb(15, 23, 42);
  background-color: #7c2d12;
  background-image: -webkit-linear-gradient(140deg, #164e63 50%, #7c2d12 50%);
}
